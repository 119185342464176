.notification-enter {
  opacity: 0;
  transform: scale(0.9);
}
.notification-enter-active {
  opacity: 1;
  transform: translateX(0);
  transition: opacity 800ms, transform 800ms;
}
.notification-exit {
  opacity: 1;
}
.notification-exit-active {
  opacity: 0;
  transform: scale(0.9);
  transition: opacity 800ms, transform 800ms;
}

