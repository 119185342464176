.snackbar-enter {
  opacity: 0;
  transform: scale(0.97);
}
.snackbar-enter-active {
  opacity: 1;
  transform: scale(1);
  transition: opacity 800ms, transform 800ms;
}
.snackbar-exit {
  opacity: 1;
}
.snackbar-exit-active {
  opacity: 0;
  transform: scale(0.97);
  transition: opacity 800ms, transform 800ms;
}

